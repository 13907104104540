export const selectionCountries = ['Беларусь', 'Украина', 'Россия', 'Казахстан', 'Узбекистан'];

export enum Ages {
  age_18 = 'under18',
  age_18_24 = '18-24',
  age_25_34 = '25-34',
  age_35_44 = '35-44',
  age_45 = '45+',
}
export enum AgesDisplayValues {
  ALL = 'all',
  UP_TO_18 = 'до 18',
  FROM_18_TO_24 = '18-24',
  FROM_25_TO_34 = '25-34',
  FROM__35_TO_44 = '35-44',
  MORE_THAN_45 = '45+',
}
export const selectionAges = [
  AgesDisplayValues.UP_TO_18,
  AgesDisplayValues.FROM_18_TO_24,
  AgesDisplayValues.FROM_25_TO_34,
  AgesDisplayValues.FROM__35_TO_44,
  AgesDisplayValues.MORE_THAN_45,
];

export const agesConverter: {
  [key in Exclude<AgesDisplayValues, AgesDisplayValues.ALL>]: Ages;
} = {
  [AgesDisplayValues.UP_TO_18]: Ages.age_18,
  [AgesDisplayValues.FROM_18_TO_24]: Ages.age_18_24,
  [AgesDisplayValues.FROM_25_TO_34]: Ages.age_25_34,
  [AgesDisplayValues.FROM__35_TO_44]: Ages.age_35_44,
  [AgesDisplayValues.MORE_THAN_45]: Ages.age_45,
};

export enum Gender {
  male = 'male',
  female = 'female',
}

export enum GenderDisplayValues {
  ALL = 'all',
  MALE = 'Парни',
  FEMALE = 'Девушки',
}
export const selectionGenders = [GenderDisplayValues.MALE, GenderDisplayValues.FEMALE];

export const genderConverter: {
  [key in Exclude<GenderDisplayValues, GenderDisplayValues.ALL>]: Gender;
} = {
  [GenderDisplayValues.MALE]: Gender.male,
  [GenderDisplayValues.FEMALE]: Gender.female,
};
export const selectionButtonUsage = ['Да', 'Нет'];
